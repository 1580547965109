exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nav-js": () => import("./../../../src/pages/nav.js" /* webpackChunkName: "component---src-pages-nav-js" */),
  "component---src-pages-project-js": () => import("./../../../src/pages/project.js" /* webpackChunkName: "component---src-pages-project-js" */),
  "component---src-pages-skill-js": () => import("./../../../src/pages/skill.js" /* webpackChunkName: "component---src-pages-skill-js" */),
  "component---src-pages-title-js": () => import("./../../../src/pages/title.js" /* webpackChunkName: "component---src-pages-title-js" */)
}

